<template>
  <v-dialog scrollable fullscreen persistent transition="dialog-bottom-transition" :value="value">
    <v-form @submit.prevent="onSubmit">
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Perfil</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-loading v-model="loading">
          <v-card-text>
            <v-container fluid class="px-0">
              <v-row align="center">
                <v-col cols="auto">
                  <v-avatar size="60" color="grey">
                    <template v-if="form.profile.avatar.path">
                      <v-img :src="form.profile.avatar.path"/>
                    </template>
                    <template v-else>
                      <span class="white--text text-uppercase" :style="{fontSize: '30px'}">
                        {{ initials }}
                      </span>
                    </template>
                  </v-avatar>
                </v-col>
                <v-col cols="auto">
                  <AvatarUploader @success="onAvatarUploadSuccess">
                    Alterar Foto
                  </AvatarUploader>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details label="Nome" v-model="form.profile.name"/>
                </v-col>
                <v-col cols="12">
                  <v-select dense outlined hide-details label="Gênero" :items="genderLists" v-model="form.profile.gender"/>
                </v-col>
                <template v-if="form.role.name === 'collaborator'">
                  <v-col cols="12">
                    <v-text-field dense outlined hide-details label="Profissão" v-model="form.profile.profession"/>
                  </v-col>
                </template>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details type="text" label="CPF" v-model="form.profile.document"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details disabled readonly type="email" label="E-mail" v-model="form.email"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined hide-details obscore label="Senha" :error="this.errors['password']" :type="typePassword" :append-icon="iconPassword" v-model="form.password" @click:append="toggleShowPassword"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="tertiary">
            <v-btn text @click="onClose">Fechar</v-btn>
            <v-spacer/>
            <v-btn depressed type="submit" color="primary">Salvar</v-btn>
          </v-card-actions>
        </v-loading>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import AuthService from '../services/auth'
  
  import AvatarUploader from '../components/AvatarUploader'

  const formInitial = () => ({
    id: '',
    email: '',
    password: '',
    profile: {
      name: '',
      document: '',
      gender: '',
      profession: '',
      relationship: '',
      avatar: {
        name: '',
        path: '',
      },
    },
    role: {
      name: '',
      display: '',
      description: '',
    }
  })

  export default {
    props: ['value'],
    components: {
      AvatarUploader,
    },
    data: () => ({
      saving: false,
      loading: false,
      showPassword: false,
      errors: {},
      genderLists: [
        {value: '', text: 'Selecione'},
        {value: '1', text: 'Masculino'},
        {value: '2', text: 'Feminino'},
      ],
      relationshipLists: [
        {value: '', text: 'Selecione'},
        {value: '1', text: 'Pai'},
        {value: '2', text: 'Mãe'},
        {value: '3', text: 'Irmão'},
        {value: '4', text: 'Irmã'},
        {value: '5', text: 'Avô'},
        {value: '6', text: 'Avó'},
        {value: '7', text: 'Tio'},
        {value: '8', text: 'Tia'},
        {value: '9', text: 'Outros'},
      ],
      form: formInitial(),
    }),
    watch: {
      value(value) {
        if (value) {
          this.mountData()
        }
      }
    },
    methods: {
      mountData() {
        this.form = formInitial()
        this.fetchMe()
      },
      fetchMe() {
        this.loading = true

        AuthService.self()
          .then(data => {
            this.form = {
              ...this.form,
              ...data
            }
          })
          .then(() => this.loading = false)
      },
      onClose() {
        this.$emit('input', false)
      },
      onCopy() {
        this.$root.$emit('snackbar', {
          text: 'Código copiado.',
          color: 'green',
        })
      },
      onSubmit() {
        AuthService.update(this.form)
          .then(data => {
            this.$store.dispatch('UpdateAuthAction', data)
              .then(() => {
                this.$root.$emit('snackbar', {
                  text: 'Dados salvos com sucesso.',
                  color: 'green',
                })
                this.onClose()
              })
          })
          .catch(data => {
            this.$root.$emit('snackbar', {
              text: 'Não foi possível salvar os dados.',
              color: 'red',
            })
            this.errors = data.errors || {}
          })
          .then(() => {
            this.saving = false
          })
      },
      onAvatarUploadSuccess(data) {
        this.form.profile.avatar = data
      },
      toggleShowPassword() {
        this.showPassword = !this.showPassword
      },
    },
    computed: {
      initials() {
        const name = this.form.profile.name || ''
        const parts = name.trim().split(' ')

        if (parts.length === 1) {
          return name.substr(0, 2)
        }

        return (parts[0][0] || '') + (parts[1][0] || '')
      },
      iconPassword() {
        return this.showPassword ? 'mdi-eye-off' : 'mdi-eye'
      },
      typePassword() {
        return this.showPassword ? 'text' : 'password'
      },
    }
  }
</script>