import http from './http'

export const avatar = (files) => {
  const formData = new FormData()

  formData.append('file', files[0])

  return http.post('upload/avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const file = (file) => {
  const formData = new FormData()

  formData.append('file', file)

  return http.post('upload/file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export default {
  avatar,
  file,
}