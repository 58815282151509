<template>
  <v-app>
    <v-navigation-drawer app floating class="drawer" v-model="drawer">
      <template #prepend>
        <v-list-item class="drawer-header">
          <v-list-item-avatar color="grey">
            <template v-if="profileAvatar">
              <v-img :src="profileAvatar"/>
            </template>
            <template v-else>
              <span class="white--text">{{ auth.user.profile.initials }}</span>
            </template>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subtitle-2 primary--text">{{ auth.user.profile.name }}</v-list-item-title>
            <v-list-item-subtitle class="caption">{{ auth.user.role.display }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn small icon @click="confirmLogout">
              <v-icon color="primary lighten-3">mdi-home-export-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      
      <v-list-item-group class="drawer-menu">
        <v-list class="py-0">
          <v-list-item @click="onEditSelf">
            <v-list-item-icon>
              <v-icon dense color="primary">
                mdi-account-box-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Perfil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item exact :to="{name: 'home'}">
            <v-list-item-icon>
              <v-icon dense color="primary">
                mdi-home-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Principal</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <template v-if="isSupervisor">
            <v-list-item exact :to="{name: 'coordinators'}">
              <v-list-item-icon>
                <v-icon dense color="primary">
                  mdi-account-switch-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Coordenadores</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="isSupervisor || isCoordinator">
            <v-list-item exact :to="{name: 'therapists'}">
              <v-list-item-icon>
                <v-icon dense color="primary">
                  mdi-account-switch-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Aplicadores</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="isSupervisor || isCoordinator">
            <v-list-item exact :to="{name: 'collaborators'}">
              <v-list-item-icon>
                <v-icon dense color="primary">
                  mdi-account-switch-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Equipe</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="isSupervisor || isCoordinator">
            <v-list-item exact :to="{name: 'students'}">
              <v-list-item-icon>
                <v-icon dense color="primary">
                  mdi-account-multiple-check-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Estudantes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="isSupervisor || isCoordinator">
            <v-list-item exact :to="{name: 'companions'}">
              <v-list-item-icon>
                <v-icon dense color="primary">
                  mdi-account-multiple-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Acompanhantes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-list-item-group>
    </v-navigation-drawer>

    <portal-target name="app-bar">
      <v-app-bar short app dark color="primary" elevation="0" height="56">
        <v-app-bar-nav-icon @click="onToggleDrawer"/>
        <v-spacer/>
        <v-img src="@/assets/images/logo.png" max-width="100" class="ml-n8"/>
        <v-spacer/>
      </v-app-bar>
    </portal-target>

    <v-main>
      <v-layout justify-center fill-height>
        <v-flex xs12 sm8>
          <router-view/>
        </v-flex>
      </v-layout>
    </v-main>

    <portal-target name="app-footer"/>

    <v-snackbar v-model="snackbar.open">
      {{ snackbar.text }}
      <template v-slot:action="{attrs}">
        <v-btn text :color="snackbar.color" v-bind="attrs" @click="snackbar.open = false">OK</v-btn>
      </template>
    </v-snackbar>

    <EditSelfDialog v-model="editSelfDialog"/>

    <v-dialog max-width="320" v-model="logoutDialog">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          Ao sair do aplicativo os atendimentos não finalizados serão perdidos. Deseja realmente sair?
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="logoutDialog = false">
            Cancelar
          </v-btn>
          <v-spacer/>
          <v-btn depressed color="primary" @click="onLogout">Sair</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
  import AuthService from '../services/auth';
  import { storeAttendances } from '../services/storage';
  import EditSelfDialog from '../dialogs/EditSelfDialog';

  export default {
    components: {
      EditSelfDialog,
    },
    data: () => ({
      drawer: null,
      editSelfDialog: false,
      logoutDialog: false,
      snackbar: {
        color: 'green',
        open: false,
        text: null
      },
    }),
    mounted() {
      this.$root.$on('drawer', () => {
        this.onToggleDrawer()
      })

      this.$root.$on('snackbar', (payload) => {
        this.snackbar = {
          open: true, color: 'green', ...payload
        }
      })
    },
    methods: {
      onToggleDrawer() {
        this.drawer = !this.drawer
      },
      onLogout() {
        AuthService.logout()
          .then(() => {
            this.$store.dispatch('RemoveAuthAction')
              .then(() => {
                this.$router.push({
                  name: 'auth.login'
                })
              })
          })
      },
      confirmLogout() {
        if (storeAttendances().length > 0) {
          this.logoutDialog = true;
        } else {
          this.onLogout();
        }
      },
      onEditSelf() {
        this.editSelfDialog = true
      }
    },
    computed: {
      isSupervisor() {
        return this.auth.user.role.name === 'supervisor'
      },
      isCoordinator() {
        return this.auth.user.role.name === 'coordinator'
      },
      auth() {
        return this.$store.getters.getStoreAuth || {}
      },
      profileAvatar() {
        return this.auth.user.profile.avatar.path || null
      }
    }
  }
</script>

<style lang="scss">
  .drawer {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    background-color: var(--v-quaternary-base);

    .drawer-header, 
    .drawer-footer {
      background-color: var(--v-tertiary-base);
    }

    .drawer-menu {
      .v-item--active {
        background: var(--v-primary-base);

        .v-list-item__icon {
          .v-icon {
            color: var(--v-white-base) !important;
          }
        }

        .v-list-item__content {
          .v-list-item__title {
            color: var(--v-white-base) !important;
          }
        }
      }
    }
  }

  .v-list-default {
    .v-list-item {
      transition: background 200ms;

      .v-list-item__content {
        .v-list-item__title,
        .v-list-item__subtitle {
          white-space: normal !important;
        }
      }

      &:hover {
        background: #eeeeee;
      }
    }
  }
</style>
