<template>
  <v-btn text color="primary" :disabled="saving" @click="onOpen">
    <input ref="input" type="file" class="d-none" @change="onChoose"/>
    <slot/>
  </v-btn>
</template>

<script>
  import UploadService from '../services/upload'

  export default {
    data: () => ({
      saving: false
    }),
    methods: {
      onOpen() {
        this.$refs.input.click()
      },
      onChoose(event) {
        UploadService.avatar(event.target.files)
          .then(data => {
            this.$emit('success', data)
          })
          .catch(() => {})
          .then(() => this.saving = false)

        this.$refs.input.value = null
      }
    }
}
</script>