import http from './http'

const login = (credentials) => {
  return http.post('auth/login', credentials)
}

const logout = () => {
  return http.post('auth/logout')
}

const register = (data) => {
  return http.post('auth/register', data)
}

const update = (data) => {
  return http.post('auth/update', data)
}

const me = () => {
  return http.get('auth/me')
}

const self = () => {
  return http.get('auth/self')
}

export default { login, logout, register, me, self, update }